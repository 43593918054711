import { Link } from 'preact-router/match';

const Header = (props) => (
	
        <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
            <div class="container px-4 px-lg-5">
                <Link class="navbar-brand" href="/">
                    <img class="img-flow" src={props.logo} style="width:200px;height:auto"/>
                </Link>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto my-2 my-lg-0">
                        <li class="nav-item"><Link class="nav-link" href="/">Home</Link></li>
                        <li class="nav-item"><Link class="nav-link" href="/nosotros">Sobre Nosotros</Link></li>
                        <li class="nav-item"><Link class="nav-link" href="/servicios">Servicios</Link></li>
                        <li class="nav-item"><Link class="nav-link" href="/procesos">Procesos</Link></li>
                        <li class="nav-item"><Link class="nav-link" href="/contacto">Contactos</Link></li>
                    </ul>
                </div>
            </div>
        </nav>

);

export default Header;
