const Footer = () => (
    <footer class="footer-07">

        <div class="row justify-content-center mx-0">
                <div class="col-md-12 text-center">
                    <img class="img-flow my-5" src="assets/img/logos/logo-full-primary.png" style="width: auto; height: 60px; margin-bottom: 20px"/>
                    <p class="menu">
                        <a href="/" style="text-decoration: none;">Home</a> 
                        <a href="/nosotros" style="text-decoration: none;">Sobre Nosotros</a> 
                        <a href="/servicios" style="text-decoration: none;">Servicios</a> 
                        <a href="/procesos" style="text-decoration: none;">Procesos</a> 
                        <a href="/contacto" style="text-decoration: none;">Contacto</a>
                    </p>
                    <div class="row px-4 px-lg-5 text-center">
                        <div class="col-md-4 col-xs-12">
                            <div class="mb-2"><i class="bi-geo-alt fs-2 text-primary"></i></div>
                            <p class="fs-6"><b>Dirección: </b>Calle El Empalme, Edf., Fedecamaras, piso 4, Oficina 4-D, Urbanización El Bosque, Caracas, Venezuela, CP 1030</p>
                        </div><div class="col-md-4 col-xs-12">
                            <div class="mb-2"><i class="bi-envelope fs-2 text-primary"></i></div>
                            <p class="fs-6"><b>Correo: </b><a href="mailto:info@consultoressc.com">info@consultoressc.com</a></p>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <div class="mb-2"><i class="bi-telephone fs-2 text-primary"></i></div>
                            <p class="fs-6" hidden=""><b>Teléfono: </b>+58 - (412) 731-3525</p>
                        </div>
                    </div>
                    <ul class="ftco-footer-social p-0">
                        <li class="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Twitter"><span class="bi-twitter"></span></a></li>
                        <li class="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Facebook"><span class="bi-facebook"></span></a></li>
                        <li class="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Instagram"><span class="bi-instagram"></span></a></li>
                    </ul>
                </div>
        </div>
        <div class="row mt-0 pt-1 mx-0" style="background: gray;">
            <div class="col-md-12 text-center">
                <p class="copyright mb-1">
                    Copyright ©<script>document.write(new Date().getFullYear());</script>2022All rights reserved | This website is made with <i class="bi-heart" aria-hidden="true" style="color: red;"></i> by <a href="https://rafaolivares.com" target="_blank">rafaolivares.com</a>
                </p>
            </div>
        </div>
    </footer>
)

export default Footer;