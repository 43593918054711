import { useState, useEffect } from 'preact/hooks';
import { Router } from 'preact-router';
import Helmet from "preact-helmet";
import ScrollToTop from '../components/scrolltotop'
import Header from './header';
import Footer from './footer';
import Fake from './fake';


import logoPrimary from '../assets/img/logos/logo-full-primary.png';
import logoWhite from '../assets/img/logos/logo-full-white.png';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Nosotros from '../routes/nosotros';
import Servicios from '../routes/servicios';
import Procesos from '../routes/procesos';
import Contacto from '../routes/contacto';


import AOS from 'aos';

const App = () => {

	const [logo, setLogo] = useState(logoWhite);
	const [pathname, setPathname] = useState("/");
	const [loading, setLoading] = useState(false);


	if (typeof window !== 'undefined') {

		const init = () => {
		
				// Navbar shrink function
				var navbarShrink = function () {
					const navbarCollapsible = document.body.querySelector('#mainNav');
					if (!navbarCollapsible) {
						return;
					}
					if (window.scrollY === 0) {
						navbarCollapsible.classList.remove('navbar-shrink');
						if(window.innerWidth < 992) {
							setLogo(logoPrimary);
						}
						else{
							setLogo(logoWhite);
						}
					} else {
						navbarCollapsible.classList.add('navbar-shrink');
						setLogo(logoPrimary);
					}
			
				};
			
				// Shrink the navbar 
				navbarShrink();
				
				AOS.init({
					duration : 900
				});
				
				// Shrink the navbar when page is scrolled
				document.addEventListener('scroll', navbarShrink);
			
				// Collapse responsive navbar when toggler is visible
				const navbarToggler = document.body.querySelector('.navbar-toggler');
				const responsiveNavItems = [].slice.call(
					document.querySelectorAll('#navbarResponsive .nav-link')
				);
				responsiveNavItems.map(function (responsiveNavItem) {
					responsiveNavItem.addEventListener('click', () => {
						if (window.getComputedStyle(navbarToggler).display !== 'none') {
							navbarToggler.click();
						}
					});
				});
			
		
	}

	const handleRoute = async e => {
		setPathname(e.url);
	};

	useEffect(() => {
		init();
	},[]);




	  return ( 
	    <div id="app" style="overflow-x: hidden; ">
			<div  id='content-wrap'>
				<Helmet
					meta={[
						{name: "viewport", content: "width=device-width,initial-scale=1"},
						{name: "mobile-web-app-capable", content: "yes"},
						{name: "apple-mobile-web-app-capable", content: "yes"}
					]}
					link={[
						{rel: "apple-touch-icon", href: "/assets/icons/apple-touch-icon.png"},
						{rel:"icon", type:"image/x-icon", href:"assets/favicon.ico"},
						{rel: "stylesheet", href: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.0/font/bootstrap-icons.css"},
						{rel: "stylesheet", href: "https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700"},
						{rel: "stylesheet", href: "https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic", type: "text/css"},
					]}
					script={[
						{src: "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js", type: "text/javascript"},
					]}
				/>
				<Header logo={logo} />
				
				<ScrollToTop pathname={pathname}>
				<Fake/>
				{loading? <div/>  :
				<Router onChange={handleRoute}>
						<Home path="/" />
						<Nosotros path="/nosotros"/>
						<Servicios path="/servicios" />
						<Procesos path="/procesos" />
						<Contacto path="/contacto" />
				</Router>}
				</ScrollToTop>
			</div>
			<Footer />
		</div>
		
		);			

	}


	
}


export default App;


