import { useEffect } from 'preact/hooks';


const ScrollToTop = (props) => {
    
  useEffect(() => {
    let tag = window.location.hash.substring(1);
    var element = document.getElementById(tag);
    if(element){
        element.scrollIntoView({behavior: "smooth"});
    } else {
        window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
        });
    }

  }, [props.pathname]);

  return props.children || null;
};

export default ScrollToTop;