import style from './style.css';

const Fake = (props) => {



    return (
        <header class={"masthead " + style.fake + " " + (props.notHome? style.notHome : "")}>
            <div class="container px-4 px-lg-5 h-100">
                <div class="row gx-4 gx-lg-5 h-50 align-items-center justify-content-center text-center">
                    {/* <div class="col-lg-8 align-self-end">
                       
                    <div class={style.skeleton +" " + style.skeleton_text} style="height: 60px"></div>
     
                     
                    </div>
                    <div class="col-lg-8 align-self-baseline mt-5">
                       
                        <div class={style.skeleton +" " + style.skeleton_text}></div>
                        <div class={style.skeleton +" " + style.skeleton_text}></div>
                        <div class={style.skeleton +" " + style.skeleton_text}></div>
                    </div> */}
                </div>
            </div>
        </header>
    )
};

export default Fake;


